exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-bio-template-js": () => import("./../../../src/templates/author-bio.template.js" /* webpackChunkName: "component---src-templates-author-bio-template-js" */),
  "component---src-templates-author-landing-template-js": () => import("./../../../src/templates/author-landing.template.js" /* webpackChunkName: "component---src-templates-author-landing-template-js" */),
  "component---src-templates-calculator-template-js": () => import("./../../../src/templates/calculator.template.js" /* webpackChunkName: "component---src-templates-calculator-template-js" */),
  "component---src-templates-content-page-template-js": () => import("./../../../src/templates/content-page.template.js" /* webpackChunkName: "component---src-templates-content-page-template-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page.template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-legal-page-template-js": () => import("./../../../src/templates/legal-page.template.js" /* webpackChunkName: "component---src-templates-legal-page-template-js" */)
}

